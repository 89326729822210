import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrackByService {

  constructor() { }

  public trackByDefault(index: number, item: { id: string }) {
    if (!item) {
      return null;
    }
    return item.id;
  }
}
