import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from '@env/environment.prod';

const credentialsHeaders = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

export interface SWToken {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_at: string;
}

export interface SWMediaResponse {
  PageNumber: number;
  PageSize: number;
  Medias: SWMedia[];
}

export interface SWMedia {
  Uuid: string;
  Source: string;
  ThumbnailUrl: string;
  NormalSizeUrl: string;
  Likes?: number;
  Author?: {
    Uuid: string,
    FullName: string,
    Username: string,
    ProfilePic: string
  };
  Description: string;
  PublicationDate: string;
  HashTags: Array<string>;
}

@Injectable({
  providedIn: 'root'
})
export class SocialWallClientService {
  private BASE_URL = 'https://socialhub.mobimanage.com';
  private authUrl = `${this.BASE_URL}/api/v2/authentication/credentials`;
  private bodyParams = new HttpParams({
    fromObject: {
      grant_type: 'application',
      client_id: environment.socialWall.client_id,
      client_secret: environment.socialWall.client_secret,
    }
  });
  constructor(
    private http: HttpClient,
  ) { }

  getMedias(socialWallObj: any, mediaPageNumber: number, mediaPageSize: number) {
    const getMediasUrl = `${this.BASE_URL}/api/v2/medias`;
    const getMediasBodyParams = new HttpParams({
      fromObject: {
        pageSize : String(mediaPageSize),
        pageNumber: String(mediaPageNumber)
      }
    });
    const dinamycBodyParams = new HttpParams({
      fromObject: {
        grant_type: 'application',
        client_id: socialWallObj.client_id,
        client_secret: socialWallObj.client_secret,
      }
    });
    return new Promise<SWMediaResponse>((resolve, reject) => {
      this.http.post<SWToken>(this.authUrl, dinamycBodyParams, credentialsHeaders).subscribe(t => {
        if (t) {
          this.http.get<SWMediaResponse>(getMediasUrl, {
            headers: new HttpHeaders({
              Authorization: `${t.token_type} ${t.access_token}`
            }),
            params: getMediasBodyParams
          }).subscribe(posts => { resolve(posts); }, err => { reject(err); });
        } else {
          reject('unknown error');
        }
      }, err => {
        reject(err);
      });
    });
  }

  async getPosts(): Promise<SWMediaResponse> {
    return new Promise<SWMediaResponse>((resolve, reject) => {
      this.http.post<SWToken>(this.authUrl, this.bodyParams, credentialsHeaders).subscribe(t => {
        if (t) {
          this.http.get<SWMediaResponse>(this.BASE_URL + '/api/v2/medias', {
            headers: new HttpHeaders({
              Authorization: `${t.token_type} ${t.access_token}`
            })
          }).subscribe(posts => { resolve(posts); }, err => { reject(err); });
        } else {
          reject('unknown error');
        }
      }, err => {
        reject(err);
      });
    });
  }
}
